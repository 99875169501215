<template>
  <div class="co-pilot">
    <div class="co-pilot__container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "CoPilot",
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // This is to serve as an additional guard to ensure users can't just get in by manually
      // inputting the url
      if (!vm.canUseBaoCoPilot) next("/login")
    })
  },
  computed: {
    ...mapGetters({
      canUseBaoCoPilot: "auth/canUseBaoCoPilot"
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
